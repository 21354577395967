@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap");

* {
	margin: 0;
	padding: 0;
}

body {
	font-family: "Montserrat", sans-serif;
	color: #58595b;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	text-decoration: none;
	color: inherit;
}

.chatPage {
	span[class*="r-marginLeft-"] {
		margin-left: 0 !important;
	}
	span[class*="r-marginLeft-"] {
		margin-right: 0 !important;
	}
}
